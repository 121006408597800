import axios from "axios";
import Vue from "vue";
import qs from "qs";
import store from "store";
import $store from "../store/index";
import storeKeys from "../store/storeKeys.js";
import { MessageBox, Loading, Message } from "element-ui";

Vue.prototype.$ajax = axios;

let loading;
let disableLoading = false;

axios.interceptors.request.use(
  config => {
    if (!disableLoading) {
      loading = Loading.service({
        fullscreen: true,
        text: "拼命加载中..."
      });
    }
    if (store.get(storeKeys.TOKEN)) {
      config.headers.token = store.get(storeKeys.TOKEN);
    }
    if (
      config.method.toLocaleLowerCase() === "post" &&
      typeof config.data === "object"
    ) {
      config.data = qs.stringify(config.data);
    }

    return config;
  },
  error => {
    closeLoading();
    return Promise.reject(error);
  }
);

function closeLoading() {
  if (loading) {
    loading.close();
  }
}

function getErrorMessage(response) {
  if (!response.data) {
    return "服务器异常,请稍后重试";
  }
  if (response.data.message) {
    return response.data.message;
  } else if (response.data.msg) {
    return response.data.msg;
  }
  return "服务器异常,请稍后重试";
}

axios.interceptors.response.use(
  response => {
    // if (response.config.url.indexOf('admin/user') !== -1) {
    //   response.data.code = 1000
    // }
    // response.data 有可能为null
    closeLoading();
    if (
      response.data &&
      typeof response.data.code !== "undefined" &&
      response.data.code * 1 === 1000 &&
      store.get(storeKeys.USER)
    ) {
      MessageBox.confirm("登录已过期，请重新登录", "", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        showClose: false
      })
        .then(() => {
          store.remove(storeKeys.TOKEN);
          store.remove(storeKeys.USER);
          window.location.reload();
        })
        .catch(error => {
          console.log("取消跳转", error);
        });
      return response;
    }
    if (response.config.method.toLocaleLowerCase() === "post") {
      return response;
    } else {
      return response;
    }
  },
  error => {
    closeLoading();
    console.error(error, "axios error");
    return {
      code: 500,
      message: "服务器错误"
    };
  }
);

/* 统一封装get请求 */
export const get = (url, params, config = {}) => {
  return new Promise((resolve, reject) => {
    axios({
      method: "get",
      url: $store.state.api + url,
      // url: ((process.env.NODE_ENV === 'production') ? 'https://www.hzzyhw.com/dtb/' : 'https://www.hzzyhw.com/dtb/') + url,
      params,
      ...config
    })
      .then(response => {
        const code = response.data.code * 1;
        if (code !== 200) {
          Message.error({ message: getErrorMessage(response), type: "error" });
        }
        resolve({
          code: code,
          data: response.data,
          message: getErrorMessage(response)
        });
      })
      .catch(error => {
        Message.error({ message: "数据获取失败", type: "error" });
        reject(error);
      });
  });
};

/* 统一封装post请求  */
export const post = (url, data, config = {}) => {
  return new Promise((resolve, reject) => {
    axios({
      method: "post",
      url: $store.state.api + url,
      // url: ((process.env.NODE_ENV === 'production') ? 'https://www.hzzyhw.com/dtb/' : 'https://www.hzzyhw.com/dtb/') + url,
      data,
      ...config
    })
      .then(response => {
        const code = response.data.code * 1;
        if (code !== 200) {
          Message.error({ message: getErrorMessage(response), type: "error" });
        }
        resolve({
          code: code,
          data: response.data,
          message: getErrorMessage(response)
        });
      })
      .catch(error => {
        Message.error({ message: "数据获取失败", type: "error" });
        reject(error);
      });
  });
};

export const disableLoadingDlg = () => {
  disableLoading = true;
};

export const enableLoadingDlg = () => {
  disableLoading = false;
};
